@import '../theme.scss';

.calendarInput {
  &.initial {
    button {
      padding: 1.5em 0.5em;

      .icon {
        svg {
          path {
            fill: #7a7a7a;
          }
        }
      }
    }
  }
  button {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    padding: 1.5em 0.5em 0.5em;
    outline: 0;
    border: none;
    border-radius: $border-radius;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: $color-light-grey;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    z-index: 2;
    width: 100%;
    text-align: left;

    .icon {
      position: absolute;
      right: 7px;
      top: 50%;
      translate: 0px -50%;

      svg {
        path {
          transition: all 0.2s ease-in-out;
          fill: $color-blue;
        }
      }
    }

    &:focus,
    &:focus-within {
      border-color: $color-blue;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      background-color: $color-white;

      .icon {
        svg {
          path {
            fill: $color-blue;
          }
        }
      }
    }
  }

  .calendar {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    border-radius: 0 0 $border-radius $border-radius;
    position: absolute;
    width: 100%;
    bottom: $border-radius;
    left: 50%;
    translate: -50% 100%;
    max-width: 480px;

    > .inner {
      grid-template-areas: 'stack';
      padding: 10px;

      .year {
        width: 100%;
        grid-area: stack;
        display: none;

        &.active {
          display: block;
        }

        .top {
          margin-bottom: 10px;

          p {
            color: $color-dark-grey;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .bottom {
          grid-template-columns: auto 1fr auto;

          .navigation {
            opacity: 0.5;
            cursor: pointer;
            width: 20px;
            display: grid;

            &.left {
              margin-right: 10px;
            }

            &.right {
              margin-left: 10px;
            }

            &.active {
              opacity: 1;
            }
          }

          .years {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            width: 100%;

            .calendarYearItem {
              display: block;
              padding: 0.5em 1em;
              text-align: center;
              cursor: pointer;
              border-radius: $border-radius;
              width: 100%;
              font-size: 11px;
              font-weight: 500;
              transition: all 0.2s ease-in-out;

              &:hover {
                background-color: $color-light-grey;
              }

              &.active {
                background-color: $color-blue;
                color: $color-white;
              }
            }
          }
        }
      }

      .month {
        width: 100%;
        grid-area: stack;
        display: none;

        &.active {
          display: block;
        }

        .top {
          margin-bottom: 10px;

          p {
            span {
              font-weight: 500;
              color: $color-black;
              display: inline-block;
              vertical-align: bottom;
              cursor: pointer;
            }

            width: 100%;
            color: $color-dark-grey;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            vertical-align: bottom;
          }
        }

        .bottom {
          .months {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            width: 100%;

            .calendarMonthItem {
              display: block;
              padding: 0.5em 1em;
              text-align: center;
              cursor: pointer;
              border-radius: $border-radius;
              width: 100%;
              font-size: 11px;
              font-weight: 500;
              transition: all 0.2s ease-in-out;

              &:hover {
                background-color: $color-light-grey;
              }

              &.active {
                background-color: $color-blue;
                color: $color-white;
              }
            }
          }
        }
      }

      .day {
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 10px;
        grid-area: stack;
        display: none;

        &.active {
          display: block;
        }

        .top {
          margin-bottom: 10px;

          p {
            span {
              font-weight: 500;
              color: $color-black;
              display: inline-block;
              vertical-align: bottom;
              cursor: pointer;
            }

            width: 100%;
            color: $color-dark-grey;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            vertical-align: bottom;
          }
        }

        .bottom {
          .days {
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 10px;
            width: 100%;

            .calendarDayItem {
              display: block;
              padding: 0.5em 1em;
              text-align: center;
              cursor: pointer;
              border-radius: $border-radius;
              width: 100%;
              font-size: 11px;
              font-weight: 500;
              transition: all 0.2s ease-in-out;

              &:hover {
                background-color: $color-light-grey;
              }

              &.active {
                background-color: $color-blue;
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }

  &.initial {
    .currentValue {
      color: $color-dark-grey;
    }
  }

  &.active {
    button {
      .icon {
        svg {
          path {
            fill: $color-blue;
          }
        }
      }
    }

    .calendar {
      max-height: 100vh;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      padding: 16px 0;
    }
  }

  &.invalid {
    button {
      border-color: $color-red;
    }
  }
}
